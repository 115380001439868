import React from 'react'

function AdminHeader() {
  return (
    <div>
      <div className="home page-template page-template-template-home page-template-template-home-php page page-id-30924 body--header-overlay tribe-no-js">
                <div
                    id="headerid"
                    className="header ally-focus-within header--menu mb-4 mb-md-0"
                >
                    <div className="header__wrap">
                        <div className=" header__container pb-4 px-lg-5">
                            <div className="d-flex">
                                <a href="/" className="malareddy-logo d-none d-lg-block">
                                    <img
                                        width="450"
                                        src="/assets/mallareddy-college-logo.png"
                                        alt="logo image"
                                        loading="lazy"
                                    />
                                </a>
                                <a href="#" className="malareddy-logo d-block d-lg-none">
                                    <img
                                        className="img-fluid"
                                        src="/assets/malla-reddy-college-logo-mobilenew.png"
                                        width="368"
                                        alt="logo image"
                                        loading="lazy"
                                    />
                                </a>
                            </div>

                            <div className="header__search">
                                <form
                                    id="search-form"
                                    className="header__form"
                                    method="get"
                                    action="#"
                                >
                                    <label className="header__label">
                                        <span className="hide">Search</span>
                                        <input
                                            type="search"
                                            className="header__input"
                                            placeholder="Type here to search"
                                            id="cludoquery"
                                            name="cludoquery"
                                        />
                                    </label>
                                    <button type="submit" className="header__submit">
                                        Go
                                    </button>
                                </form>
                            </div>
                            <button className="header__menu-toggle">
                                <span className="hide">Menu</span>
                            </button>
                            <div className="header__menu">
                                <div className="header__menu__wrap">
                                    <img
                                        className="img-fluid pb-lg-5 header-logo"
                                        src="/assets/logo/new-top-iconsnew.png"
                                        alt="icon"
                                        loading="lazy"
                                    />
                                    <nav className="header__primary">
                                        <ul
                                            id="menu-navigation"
                                            className="header__primary__list "
                                        >
                                            <li
                                                id="menu-item-639 "
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639 "
                                            >
                                                <a href="/">Home</a>
                                            </li>
                                            <li
                                                id="menu-item-639"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639 "
                                            >
                                                <a href="/Home/About">About College</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/InfrastructureFacilities">
                                                    Infrastructure
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Admissions">Admissions</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Departments">Departments</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Placements">Placements</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Faculty"> Faculty</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                {/*<a href="/Home/Non_Teaching"> Non Teaching Staff</a>*/}
                                                <a href="#"> Non Teaching Staff</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Courses">Courses</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Events">Events</a>
                                            </li>

                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Careers">Careers</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Alumni">Alumni</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                                            >
                                                <a href="/Home/Contact">Contact</a>
                                            </li>
                                            <li
                                                id="menu-item-640"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640 "
                                            >
                                                <a href="https://mrecwexamcell.com/" target="_blank">
                                                    <b>
                                                        <i className="fa-solid fa-right-to-bracket loginIon"></i>
                                                        Login
                                                    </b>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="scrollto" className="scrollto"></div>
                    <div className="omnilert">
                        <div className="omnilert__container container">
                            <script type="text/javascript"
                                src="http://widgets.omnilert.net/5fb267efe64a62be1d534df4ecebcee8-621"></script>
                            <button className="omnilert__close">Close</button>
                        </div>
                    </div> */}
            </div>
            <div className=" d-flex align-items-cente marqueeeLine mt-0">
                <p className="news-heading">
                    <i className="fa fa-bullhorn small text-white fa-shake"></i>
                    &nbsp;UPDATES{" "}
                </p>
                <marquee
                    className="col-md-11 y p-1"
                    behavior="scroll"
                    direction="left"
                    scrollamount="5"
                >
                    <a className="text-white">
                        * B.Tech 2020-24 Batch Placements: 510 Students placed in
                        Accenture | 404 Students placed in Capgemini | 60 Students placed
                        in KPIT &nbsp;&nbsp;&nbsp; * Hearty congratulations to the winners
                        (A.Vaishnavi, G.Sahruthi, C.Harshini of IV CSE-AIML and
                        G.Rishwitha, G.Abhinaya, G.Meghana of III ECE) of MSME IDEA
                        HACKATHON 3.0 (WOMEN). &nbsp;&nbsp;&nbsp; * Hearty congratulations
                        to the winners (students of II.B.Tech CSE-CS of MRECW) of Smart
                        India Hackathon 2023. &nbsp;&nbsp;&nbsp; * Hearty congratulations
                        to the winners (students of II.B.Tech CSE-CS of MRECW) of Smart
                        India Hackathon 2023
                    </a>
                    <a href="#" className="text-white">
                        <i className="fa-solid fa-star text-white"></i>III B.Tech CSE
                        Students won 1st prize (Aayushi Hotta, B Sneha, C Aditi, PVS
                        Lakshmi Anuhya) and 3rd prize (D.Sri Sravya, B.Manasa, N.Nayana
                        Latha, T.Nalini Devi) in ImmerseTech AR-VR HACKATHON conducted by
                        JTBI.
                        <i className="fa-solid fa-star text-white"></i>
                        Accredited by NAAC with ‘A+’ Grade.
                        <i className="fa-solid fa-star text-white"></i>
                        National Ranking by NIRF Innovation – Rank band (151-300), MHRD,
                        Govt. of India.
                        <i className="fa-solid fa-star text-white"></i>
                        Ranked 13th in Top Emerging Engineering Colleges of Super
                        Excellence by CSR Rankings-2024.
                        <i className="fa-solid fa-star text-white"></i>
                        Ranked 9th in Top Engineering Colleges of Eminence by GHRDC
                        Engineering Colleges Survey-2024.
                    </a>
                </marquee>
            </div>
    </div>
  )
}

export default AdminHeader
