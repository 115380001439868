import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import AdminDashboard from "./components/AdminMrecw/AdminDashboard";
import AdminEvents from "./components/AdminMrecw/AdminEvents";
import AdminExamSchedules from "./components/AdminMrecw/AdminExamSchedules";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/Admin/Dashboard',
    element: <AdminDashboard />
  },
  {
    path: '/Admin/Events',
    element: <AdminEvents />
  },
  {
    path: '/Admin/ExamSchedules',
    element: <AdminExamSchedules />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  }
];

export default AppRoutes;
