import React, { Component } from "react";
import "./NavMenu.css";
import $ from "jquery";

export class Home extends Component {
  HidePopup() {
    var Popup = $("#EnquiryForm");
    var closebtn = $("#closebtn");
  }
  ShowDropdown() {
    var dropdown = $("#NaacDropdown");
    var DropdownIcon = $("#DropdownIcon");
    if (dropdown.hasClass("d-none")) {
      dropdown.removeClass("d-none");
      dropdown.addClass("d-block");
      DropdownIcon.removeClass("fa-caret-right");
      DropdownIcon.addClass("fa-caret-down");
    } else {
      dropdown.removeClass("d-block");
      dropdown.addClass("d-none");
      DropdownIcon.removeClass("fa-caret-down");
      DropdownIcon.addClass("fa-caret-right");
    }
  }
  shoot(event, someParameter, activeClass) {
    $(".card-group__item").removeClass("card-group__item--top");

    $(".card-group__item" + someParameter).addClass("card-group__item--top");
  }

  componentDidMount() {
    //      $(".btn1").click(function(){
    //          $(".page1").addClass('showItems');
    //});
    $("marquee")
      .mouseover(function () {
        this.stop();
      })
      .mouseout(function () {
        this.start();
      });

    function startVideo(videoId) {
      var video = document.getElementById(videoId);
      video.src += "?autoplay=1";
      document.querySelector("#" + videoId + " button").style.display = "none";
    }

    $(".accordion-list > li > .answer").hide();

    $(".accordion-list > li").click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active").find(".answer").slideUp();
      } else {
        $(".accordion-list > li.active .answer").slideUp();
        $(".accordion-list > li.active").removeClass("active");
        $(this).addClass("active").find(".answer").slideDown();
      }
      return false;
    });

    $(".card-group__title").show("slow");
    $(".page1").show("slow");
    $(".btn1").click(function () {
      $(".page1").show("slow");
      $(".page2").hide("slow");
      $(".page3").hide("slow");
      $(".page4").hide("slow");
      $(".page5").hide("slow");
      $(".page5").hide("slow");
      $(".page1").addClass("card - group__button--nav--active");
      $(".page2").removeClass("card - group__button--nav--active");
      $(".page3").removeClass("card - group__button--nav--active");
      $(".page4").removeClass("card - group__button--nav--active");
    });
    $(".btn2").click(function () {
      $(".page1").hide("slow");
      $(".page2").show("slow");
      $(".page3").hide("slow");
      $(".page4").hide("slow");
      $(".page5").hide("slow");
      $(".page1").removeClass("card - group__button--nav--active");
      $(".page2").addClass("card - group__button--nav--active");
      $(".page3").removeClass("card - group__button--nav--active");
      $(".page4").removeClass("card - group__button--nav--active");
    });
    $(".btn3").click(function () {
      $(".page1").hide("slow");
      $(".page2").hide("slow");
      $(".page3").show("slow");
      $(".page4").hide("slow");
      $(".page5").hide("slow");
      $(".page1").removeClass("card - group__button--nav--active");
      $(".page2").removeClass("card - group__button--nav--active");
      $(".page3").addClass("card - group__button--nav--active");
      $(".page4").removeClass("card - group__button--nav--active");
    });
    $(".btn4").click(function () {
      $(".page1").hide("slow");
      $(".page2").hide("slow");
      $(".page3").hide("slow");
      $(".page4").show("slow");
      $(".page5").hide("slow");
    });
    $(".btn5").click(function () {
      $(".page1").hide("slow");
      $(".page2").hide("slow");
      $(".page3").hide("slow");
      $(".page4").hide("slow");
      $(".page5").show("slow");
    });

    $(".accordion button").on("click", function () {
      const itemToggle = $(this).attr("aria-expanded");

      $(".accordion button").attr("aria-expanded", "false");

      if (itemToggle == "false") {
        $(this).attr("aria-expanded", "true");
      }
    });
    //$('#myCarousel').carousel({
    //    interval: 500
    //});

    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $("#back-to-top").fadeIn();
      } else {
        $("#back-to-top").fadeOut();
      }
    });

    $("#back-to-top").click(function () {
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
    $("#headerid").removeClass("header--menu");
    $(".header__menu-toggle").click(function () {
      $("#headerid").toggleClass("header--menu");
    });
  }

  render() {
    return (
      <div>
        <div id="myModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body text-center">
                <h1>Full screen Transparent Bootstrap Modal</h1>
                <p>FEEL FRREE TO GET YOUR MODAL CODE HERE FOLKS.</p>
                <a className="pre-order-btn" href="#">
                  GET THE MODAL CODE
                </a>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        <div className="home page-template page-template-template-home page-template-template-home-php page page-id-30924 body--header-overlay tribe-no-js">
          <div
            id="headerid"
            className="header ally-focus-within header--menu mb-4 mb-md-0"
          >
            <div className="header__wrap">
              <div className=" header__container pb-4 px-lg-5">
                <div className="d-flex">
                  <a href="/" className="malareddy-logo d-none d-lg-block">
                    <img
                      width="450"
                      src="/assets/mallareddy-college-logo.png"
                      alt="logo image"
                      loading="lazy"
                    />
                  </a>
                  <a href="#" className="malareddy-logo d-block d-lg-none">
                    <img
                      className="img-fluid"
                      src="/assets/malla-reddy-college-logo-mobilenew.png"
                      width="368"
                      alt="logo image"
                      loading="lazy"
                    />
                  </a>
                </div>

                <div className="header__search">
                  <form
                    id="search-form"
                    className="header__form"
                    method="get"
                    action="#"
                  >
                    <label className="header__label">
                      <span className="hide">Search</span>
                      <input
                        type="search"
                        className="header__input"
                        placeholder="Type here to search"
                        id="cludoquery"
                        name="cludoquery"
                      />
                    </label>
                    <button type="submit" className="header__submit">
                      Go
                    </button>
                  </form>
                </div>
                <button className="header__menu-toggle">
                  <span className="hide">Menu</span>
                </button>
                <div className="header__menu">
                  <div className="header__menu__wrap">
                    <img
                      className="img-fluid pb-lg-5 header-logo"
                      src="/assets/logo/new-top-iconsnew.png"
                      alt="icon"
                      loading="lazy"
                    />
                    <nav className="header__primary">
                      <ul
                        id="menu-navigation"
                        className="header__primary__list "
                      >
                        <li
                          id="menu-item-639 "
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639 "
                        >
                          <a href="/">Home</a>
                        </li>
                        <li
                          id="menu-item-639"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-639 "
                        >
                          <a href="/Home/About">About College</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/InfrastructureFacilities">
                            Infrastructure
                          </a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Admissions">Admissions</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Departments">Departments</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Placements">Placements</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Faculty"> Faculty</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          {/*<a href="/Home/Non_Teaching"> Non Teaching Staff</a>*/}
                          <a href="#"> Non Teaching Staff</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Courses">Courses</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Events">Events</a>
                        </li>

                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Careers">Careers</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Alumni">Alumni</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640"
                        >
                          <a href="/Home/Contact">Contact</a>
                        </li>
                        <li
                          id="menu-item-640"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-640 "
                        >
                          <a href="https://mrecwexamcell.com/" target="_blank">
                            <b>
                              <i className="fa-solid fa-right-to-bracket loginIon"></i>
                              Login
                            </b>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="scrollto" className="scrollto"></div>
                    <div className="omnilert">
                        <div className="omnilert__container container">
                            <script type="text/javascript"
                                src="http://widgets.omnilert.net/5fb267efe64a62be1d534df4ecebcee8-621"></script>
                            <button className="omnilert__close">Close</button>
                        </div>
                    </div> */}
        </div>

        <div className=" d-flex align-items-cente marqueeeLine mt-0">
          <p className="news-heading">
            <i className="fa fa-bullhorn small text-white fa-shake"></i>
            &nbsp;UPDATES{" "}
          </p>
          <marquee
            className="col-md-11 y p-1"
            behavior="scroll"
            direction="left"
            scrollamount="5"
          >
            <a className="text-white">
              * B.Tech 2020-24 Batch Placements: 510 Students placed in
              Accenture | 404 Students placed in Capgemini | 60 Students placed
              in KPIT &nbsp;&nbsp;&nbsp; * Hearty congratulations to the winners
              (A.Vaishnavi, G.Sahruthi, C.Harshini of IV CSE-AIML and
              G.Rishwitha, G.Abhinaya, G.Meghana of III ECE) of MSME IDEA
              HACKATHON 3.0 (WOMEN). &nbsp;&nbsp;&nbsp; * Hearty congratulations
              to the winners (students of II.B.Tech CSE-CS of MRECW) of Smart
              India Hackathon 2023. &nbsp;&nbsp;&nbsp; * Hearty congratulations
              to the winners (students of II.B.Tech CSE-CS of MRECW) of Smart
              India Hackathon 2023
            </a>
            <a href="#" className="text-white">
              <i className="fa-solid fa-star text-white"></i>III B.Tech CSE
              Students won 1st prize (Aayushi Hotta, B Sneha, C Aditi, PVS
              Lakshmi Anuhya) and 3rd prize (D.Sri Sravya, B.Manasa, N.Nayana
              Latha, T.Nalini Devi) in ImmerseTech AR-VR HACKATHON conducted by
              JTBI.
              <i className="fa-solid fa-star text-white"></i>
              Accredited by NAAC with ‘A+’ Grade.
              <i className="fa-solid fa-star text-white"></i>
              National Ranking by NIRF Innovation – Rank band (151-300), MHRD,
              Govt. of India.
              <i className="fa-solid fa-star text-white"></i>
              Ranked 13th in Top Emerging Engineering Colleges of Super
              Excellence by CSR Rankings-2024.
              <i className="fa-solid fa-star text-white"></i>
              Ranked 9th in Top Engineering Colleges of Eminence by GHRDC
              Engineering Colleges Survey-2024.
            </a>
          </marquee>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="7"
              aria-label="Slide 8"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="8"
              aria-label="Slide 9"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="9"
              aria-label="Slide 10"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="10"
              aria-label="Slide 11"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="11"
              aria-label="Slide 12"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="12"
              aria-label="Slide 13"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="13"
              aria-label="Slide 14"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="14"
              aria-label="Slide 15"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="15"
              aria-label="Slide 16"
            ></button>
            {/*<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="15" aria-label="Slide 16"></button>*/}
            {/*<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="16" aria-label="Slide 17"></button>*/}
            {/*<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="17" aria-label="Slide 18"></button>*/}
          </div>
          <div className="carousel-inner">
            <style>
              {`
          .important-height {
            height: 200px !important;
          }
        `}
            </style>
            <div className="carousel-item active" data-bs-interval="1500">
              <img
                src="./assets/banner/block-1.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/block-1.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/block-2.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/block-2.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/block-3.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/block-3.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/block-4.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/block-4.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/block-5.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/block-5.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/block-6.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/block-6.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/NAAC-APlus.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/NAAC-APlus.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/CSR2024.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/CSR2024.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/GHRDC-SURVEY-2024.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/GHRDC-SURVEY-2024.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            {/*<div className="carousel-item" data-bs-interval="3000">*/}
            {/*    <img src="./assets/banner/4. DATA QUEST 2024.png" className="d-none d-md-block w-100" alt="image" style={{ height: "500px", width: "100%" }} />*/}
            {/*    <img src="./assets/banner/4. DATA QUEST 2024.png" className="d-block d-md-none w-100 important-height" alt="image" />*/}
            {/*</div>*/}
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/PLACEMENT-2024.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/PLACEMENT-2024.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/GREEN-RANKINGS-2024.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/GREEN-RANKINGS-2024.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/JNTUH-HACKATHON.png"
                className="d-none d-md-block w-100"
                alt="image"
                style={{ height: "500px", width: "100%" }}
              />
              <img
                src="./assets/banner/JNTUH-HACKATHON.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            {/*<div className="carousel-item" data-bs-interval="3000">*/}
            {/*    <img src="./assets/banner/mrecw-rankings.png" className="d-none d-md-block w-100" alt="image" />*/}
            {/*    <img src="./assets/mobile-banner/MOBILE-raking.png" className="d-block d-md-none w-100 important-height" alt="image" />*/}
            {/*</div>*/}
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/SIH-2023&2022-PHOTO.png"
                className="d-none d-md-block w-100"
                alt="image"
              />
              <img
                src="./assets/banner/SIH-2023&2022-PHOTO.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/madhavee-latha-principal.png"
                className="d-none d-md-block w-100"
                alt="image"
              />
              <img
                src="./assets/mobile-banner/mobile-banner-10.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/madhavee-latha-NEWS.png"
                className="d-none d-md-block w-100"
                alt="image"
              />
              <img
                src="./assets/mobile-banner/MOBILE-madhavee-latha-NEWS.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img
                src="./assets/banner/gold-medal.png"
                className="d-none d-md-block w-100"
                alt="image"
              />
              <img
                src="./assets/mobile-banner/mobile-banner-9.png"
                className="d-block d-md-none w-100 important-height"
                alt="image"
              />
            </div>
            {/*<div className="carousel-item" data-bs-interval="3000">*/}
            {/*    <img src="./assets/banner/play-ground.jpg" className="d-none d-md-block w-100" alt="image" />*/}
            {/*    <img src="./assets/mobile-banner/mobile-banner-7.png" className="d-block d-md-none w-100 important-height" alt="image" />*/}
            {/*</div>*/}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="row card-group">
          <div className="col-lg-3 order-2 order-lg-1">
            <div className="sideBtnBar mx-4 mx-lg-0 ms-lg-5 pt-0">
              <aside className="Homesidebar">
                <nav className="sideNav">
                  <ul>
                    <li>
                      <a href="/Home/Management">
                        <i className="fa-solid fa-caret-right"></i> Management
                      </a>
                    </li>

                    <li>
                      <a href="/Home/Principal">
                        <i className="fa-solid fa-caret-right"></i> Principal
                      </a>
                    </li>

                    {/*<li>*/}
                    {/*    <a href="/Home/Dean"><i className="fa-solid fa-caret-right"></i> Dean Academics </a>*/}
                    {/*</li>*/}
                    <li>
                      <a href="/Home/Directors">
                        <i className="fa-solid fa-caret-right"></i> Deans{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/documents/GOVERNING BODY 2023.pdf"
                        target="_blank"
                      >
                        {" "}
                        <i className="fa-solid fa-caret-right"></i> Governing
                        Body
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Regulations">
                        <i className="fa-solid fa-caret-right"></i> Academics{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Exambranchautonomous">
                        <i className="fa-solid fa-caret-right"></i> Examination
                        Branch
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="/Home/MRECW_Cells"><i className="fa-solid fa-caret-right"></i> MRECW Cells</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/MRECW_Committees"><i className="fa-solid fa-caret-right"></i> MRECW Committes</a>*/}
                    {/*</li>*/}
                    <li>
                      <a href="/Home/Grievance">
                        <i className="fa-solid fa-caret-right"></i> Grievances
                        Redressal Committee
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Anti_Ragging_Committee">
                        <i className="fa-solid fa-caret-right"></i> Anti Ragging
                        Committee
                      </a>
                    </li>
                    <li>
                      <a href="/Home/SC_ST_Committee">
                        <i className="fa-solid fa-caret-right"></i> SC/ST
                        Committee
                      </a>
                    </li>
                    <li>
                      <a href="/Home/OBC_Cell">
                        <i className="fa-solid fa-caret-right"></i> OBC Cell
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Minority_Cell">
                        <i className="fa-solid fa-caret-right"></i> Minority
                        Cell
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Internal_Complaints_Committee">
                        <i className="fa-solid fa-caret-right"></i> Internal
                        Complaints Committee
                      </a>
                    </li>

                    {/*<li>*/}
                    {/*    <a href="/Home/WomenProtecitionCell"><i className="fa-solid fa-caret-right"></i> Women Protection Cell</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/Grievance"><i className="fa-solid fa-caret-right"></i> Grievances Redressal Cell</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/assets/documents/ombudsperson.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Ombudsperson Details</a>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <a href="/assets/images/onlinecertifications.jpg" target="_blank"><i className="fa-solid fa-caret-right"></i> Online Certifications</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/Tech_Clubs"><i className="fa-solid fa-caret-right"></i> MRECW Technical Clubs</a>*/}
                    {/*</li>*/}
                    <li>
                      <a href="/Home/StudentsChapters">
                        <i className="fa-solid fa-caret-right"></i> Student
                        Chapters
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="/Home/IEEE_SB"><i className="fa-solid fa-caret-right"></i> IEEE SB</a>*/}
                    {/*</li>*/}
                    {/*style={{ "background": "#c32026" }}*/}
                    <li>
                      <p
                        className="mb-2 mt-2 text-white  ps-2"
                        onClick={this.ShowDropdown}
                      >
                        <i
                          className="fa-solid fa-caret-right"
                          id="DropdownIcon"
                        ></i>{" "}
                        NAAC Reports
                      </p>
                      <div
                        className="d-none user-select-none"
                        id="NaacDropdown"
                      >
                        <a
                          href="/assets/documents/NAAC-SSR-REPORT 2024.pdf"
                          target="_blank"
                        >
                          <i className="fa-solid fa-caret-right"></i> NAAC SSR
                          Report
                        </a>
                        <a
                          href="/assets/documents/NAAC 2024 Institutional_Grade_Sheet.pdf"
                          target="_blank"
                        >
                          <i className="fa-solid fa-caret-right"></i>{" "}
                          Institutional Grade Sheet
                        </a>
                        <a
                          href="/assets/documents/NAAC 2024 PEER TEAM REPORT.pdf"
                          target="_blank"
                        >
                          <i className="fa-solid fa-caret-right"></i> Peer Team
                          Report
                        </a>
                      </div>
                    </li>
                    <li>
                      <a href="/Home/IQAC">
                        <i className="fa-solid fa-caret-right"></i> IQAC
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="~/assets/documents/ANTIRAGGING2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Anti - Ragging Committee</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/assets/documents/DISIPLINARY2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Disciplinary Committee</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/assets/documents/SC_STCommittee2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> SC/ST Committee</a>*/}
                    {/*</li>*/}

                    <li>
                      <a href="/Home/NSSunit">
                        <i className="fa-solid fa-caret-right"></i> NSS Unit
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/documents/AcademicCouncil.pdf"
                        target="_blank"
                      >
                        <i className="fa-solid fa-caret-right"></i> Academic
                        Council
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/documents/Mandatory_Disclosure_MRECW.pdf"
                        target="_blank"
                      >
                        <i className="fa-solid fa-caret-right"></i> Mandatory
                        Disclosure
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Feedback">
                        <i className="fa-solid fa-caret-right"></i> Feedback on
                        Curriculum
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.aicte-india.org/feedback/index.php"
                        target="_blank"
                      >
                        <i className="fa-solid fa-caret-right"></i> Feedback
                        AICTE
                      </a>
                    </li>
                    <li>
                      <a href="/Home/EContent">
                        <i className="fa-solid fa-caret-right"></i> E-Content
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="/Home/nirf"><i className="fa-solid fa-caret-right"></i> NIRF Ranking</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/Ariia"><i className="fa-solid fa-caret-right"></i> ARIIA Ranking</a>*/}
                    {/*</li>*/}
                    <li>
                      <a href="/Home/Nisp">
                        <i className="fa-solid fa-caret-right"></i> MRECW NISP
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/documents/Code of Conduct-Students.pdf"
                        target="_blank"
                      >
                        <i className="fa-solid fa-caret-right"></i> Code of
                        Conduct
                      </a>
                    </li>

                    <li>
                      <a href="/assets/documents/SERVICE RULES.pdf">
                        <i className="fa-solid fa-caret-right"></i> Service
                        Rules
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="/Home/RandD"><i className="fa-solid fa-caret-right"></i> Research &amp; Development Cell</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/IIPC"><i className="fa-solid fa-caret-right"></i> Industry Institute Partnership Cell</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/Edcell"><i className="fa-solid fa-caret-right"></i> Entreprenuer Development Cell</a>*/}
                    {/*</li>*/}
                    <li>
                      <a href="/Home/Tbi">
                        <i className="fa-solid fa-caret-right"></i> Technology
                        Business Incubation Centre
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Centres_of_Excellence">
                        <i className="fa-solid fa-caret-right"></i> Centres of
                        Excellence
                      </a>
                    </li>
                    <li>
                      <a href="/Home/MRECW_cells_and_committees">
                        <i className="fa-solid fa-caret-right"></i> MRECW
                        Cells & Committees
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="/Home/Cdcskills"><i className="fa-solid fa-caret-right"></i> Centre for Development of Communication Skills</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/Mentoring"><i className="fa-solid fa-caret-right"></i> Mentoring &amp; Counselling Cell</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/VADDC"><i className="fa-solid fa-caret-right"></i> Value Added Certification Courses</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/Home/CareerGuidance"><i className="fa-solid fa-caret-right"></i> Career Guidance</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/assets/documents/Internal Complaints Committee 2022.pdf" target="_blank"><i className="fa-solid fa-caret-right"></i> Internal Complaints Committee</a>*/}
                    {/*</li>*/}

                    <a
                      href="http://staging.mallareddyecw.com/MRECWNEW/MRECWNEW.html"
                      target="blanbk"
                    >
                      <img
                        className="img-fluid mx-auto "
                        src="/assets/navbarImages/leftImages/left-1.png"
                        alt="loginImgg"
                        loading="lazy"
                      />
                    </a>
                    <a href="https://mrecwexamcell.com/" target="blanbk">
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-2.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://ssolive.myclassboard.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DDIL4KLQ05IV1JZOXCLM0%26redirect_uri%3Dhttps%253A%252F%252Ficici.myclassboard.com%252Fsso%252FCallback%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access"
                      target="blanbk"
                    >
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-3.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>
                    <a href="/Home/TermsConditions">
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-4.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>
                    <a href="/Home/Library">
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-10.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>
                    {/* <a href="/Home/Newsletter"><img className="img-fluid pt-2 mx-auto" src="/assets/navbarImages/leftImages/left-5.png" alt="sideImg" loading="lazy" /></a>*/}
                    <a
                      href="http://www.publishingindia.com/ijrsp"
                      target="_blank"
                    >
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-8.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>
                    <a href="/Home/Media">
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-7.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="../assets/images/media/new2Category B Application Form.pdf"
                      target="_blank"
                    >
                      <img
                        className="img-fluid pt-1 mx-auto"
                        src="/assets/navbarImages/leftImages/left-6.png"
                        alt="sideImg"
                        loading="lazy"
                      />
                    </a>

                    {/*<a target='_blank' className="mt-1" href="https://www.youtube.com/@MRECWonline/featured">*/}
                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/youtbe.png" alt="blog" loading="lazy" />*/}
                    {/*</a>*/}
                    {/*<a target='_blank' className="mt-2" href="https://www.instagram.com/mrecwautonomous/">*/}
                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/instagram.png" alt="blog" loading="lazy" />*/}
                    {/*</a>*/}
                    {/*<a target='_blank' className="mt-2" href="https://www.facebook.com/MRECWonline?ref=embed_page">*/}
                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/social-media/facebook.png" alt="blog" loading="lazy" />*/}
                    {/*</a>*/}
                  </ul>
                </nav>
              </aside>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="container--lg container--home  px-3 px-lg-0 ally-focus-within">
              <div className="feature fade-in fade-in--active ally-focus-within mt-0">
                <div className="feature__text">
                  <h4 className="for-admission-text">
                    <span>About</span> <br />
                    Malla Reddy Engineering College for Women
                  </h4>
                  <p>
                    Malla Reddy Engineering College for Women -MRECW (Autonomous
                    Institution, UGC, Govt. of India), established in 2008, is
                    approved by AICTE, New Delhi and affiliated to JNT
                    University, Hyderabad.
                  </p>

                  <p>
                    <a href="/Home/About" className="button">
                      View More
                    </a>
                  </p>
                </div>
                <div className="feature__image ally-focus-within">
                  <a href="#" className="ally-focus-within">
                    <img
                      width="792"
                      height="529"
                      src="./assets/global/college-pic.png"
                      className="attachment-md-lg size-md-lg"
                      alt="aboutImg"
                      loading="lazy"
                      sizes="(max-width: 792px) 100vw, 792px"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/*<div className="image-grid py-3 mb-4 mb-lg-5">*/}
            {/*    <div className="container container--md image-grid__container fade-in fade-in--active">*/}
            {/*        <div className="image-grid__content mt-4">*/}
            {/*            <h6>*/}
            {/*                Welcome to Malla Reddy Engineering College for Women*/}
            {/*            </h6>*/}
            {/*            <p><a className="button button--inline" href="/Home/Admissions">Admissions Open</a></p>*/}
            {/*        </div>*/}
            {/*        <div className="image-grid__items">*/}

            {/*            <div className="image-grid__item">*/}
            {/*                <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">*/}
            {/*                    <img width="400" height="300" src='/assets/admission-imgs/Welcome-1.png' className="" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" alt="studentsLife" />*/}

            {/*                </a>*/}
            {/*            </div>*/}
            {/*            <div className="image-grid__item">*/}
            {/*                <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">*/}
            {/*                    <img width="400" height="300" src='/assets/admission-imgs/Welcome-2.png' className="attachment-width-400 size-width-400" alt="studentsLife" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" />*/}

            {/*                </a>*/}
            {/*            </div>*/}
            {/*            <div className="image-grid__item">*/}
            {/*                <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">*/}
            {/*                    <img width="400" height="300" src='/assets/admission-imgs/Welcome-3.png' className="attachment-width-400 size-width-400" alt="studentsLife" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" />*/}

            {/*                </a>*/}
            {/*            </div>*/}

            {/*            <div className="image-grid__item">*/}
            {/*                <a className="image-grid__link" href="javascript:;" data-minimodal="image-grid-2" title="">*/}
            {/*                    <img width="400" height="300" src='/assets/admission-imgs/Welcome-4.png' className="attachment-width-400 size-width-400" alt="studentsLife" loading="lazy" sizes="(max-width: 400px) 100vw, 400px" />*/}

            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="news-events mission-section mb-4 mb-lg-5">
              <div className="container container--md news-events__container fade-in fade-in--active">
                <div className="news-events__group">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src="/assets/vision.png"
                      className="vision-icon img-fluid"
                      height="100"
                    />
                    <h4 className=" news-events__heading videos-border">
                      Vision
                    </h4>
                  </div>

                  <div className="news-events__list">
                    <div className="news-events__item">
                      <div className="news-events__text">
                        <ul className="list">
                          <li>
                            Visualizing a great future for the intelligentsia by
                            imparting state-of the art Technologies in the field
                            of Engineering and Technology for the bright future
                            and prosperity of the students.
                          </li>
                          <li>
                            To offer world className training to the promising
                            Engineers.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="news-events__group mt-3 mt-lg-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src="/assets/mission.png"
                      className="vision-icon img-fluid me-1 "
                      height="100"
                    />
                    <h4 className="news-events__heading videos-border ">
                      {" "}
                      Mission
                    </h4>
                  </div>

                  <div className="news-events__list">
                    <div className="news-events__item">
                      <div className="news-events__text">
                        <ul className="list">
                          <li>
                            To nurture high level of Decency, Dignity and
                            Discipline in women to attain high intellectual
                            abilities.
                          </li>
                          <li>
                            To produce employable students at National and
                            International levels by effective training
                            programmes.
                          </li>
                          <li>
                            To create pleasant academic environment for
                            generating high level learning attitudes
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="white-bg-text-con">
                <div className=" px-3 py-0">
                  <h4 className=" for-admission-text">
                    MRECW - prosperity through innovation
                  </h4>
                </div>

                {/*<div className="accordion accordion-flush" id="accordionFlushExample">*/}
                {/*    <div className="accordion-item">*/}
                {/*        <h2 className="accordion-header" id="flush-headingOne" onclick="toggle(event)">*/}
                {/*            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">*/}
                {/*                Special Achievements -  MRECW*/}
                {/*            </button> */}
                {/*        </h2>*/}
                {/*        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">*/}
                {/*            <div className="accordion-body">*/}

                {/*                <ul className="list">*/}
                {/*                    <li>Autonomous Institution, UGC, Govt. of India.</li>*/}
                {/*                    <li>Accredited By NBA, AICTE, New Delhi.</li>*/}
                {/*                    <li>Accredited By NAAC with 'A' Grade, UGC, Govt of India.</li>*/}
                {/*                    <li>NIRF-Indian Ranking - 2020 &amp; 2018, Accepted by MHRD, Govt of India.</li>*/}
                {/*                    <li>Band Excellent National Ranking in Atal Ranking of Institution on Innovation Achievements ( ARIIA), MHRD, Govt of India.</li>*/}
                {/*                    <li>ISO 9001 : 2015 Certified Institution.</li>*/}
                {/*                    <li>2(f) &amp; 12(B) Recognition, UGC, Govt of India.</li>*/}
                {/*                    <li>Ranked as Top Engineering College of Eminence in India by CSR-2022.</li>*/}
                {/*                    <li>Platinum Rated by AICTE-CII Survey of Industry linked Technical Institutes.</li>*/}
                {/*                    <li>Four Star rating Form Institution Innovation Council (IIC) , MHRD, Govt of India to promote Innovation and Startup.</li>*/}
                {/*                    <li>AAAA Rated by Careers 360 Magazine.</li>*/}
                {/*                    <li>AAAA+ Rated by Digital Learning Magazine.</li>*/}
                {/*                    <li>51st National Ranking &amp; 5th Telangana State Ranking by Time Magazine.</li>*/}
                {/*                    <li>86th National Ranking by The Week Magazine.</li>*/}
                {/*                    <li>Top 10 Women Colleges in India-2022 by Women Entrepreneur India Magazine.</li>*/}
                {/*                    <li>132 Rank By India Today best Engineering Colleges of India Rankings - 2022.</li>*/}
                {/*                    <li>National Employability Award 2021- for Being the top 10% college in India by AMCAT.</li>*/}
                {/*                    <li>"Best College of the Year" Award from Education Matters.</li>*/}
                {/*                    <li>"College of the Year Award" - Silicon India Education.</li>*/}
                {/*                    <li>"Best Engineering College in Telangana &amp; A.P Award" from International Education Award, Time Cyber Media Pvt. Ltd.</li>*/}
                {/*                    <li>"Education Leadership Award" From Dewang Mehta National Education Award.</li>*/}
                {/*                    <li>100 % students placed in Reputed MNCs during 2021-2022.</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="accordion-item">*/}
                {/*        <h2 className="accordion-header" id="flush-headingTwo" onclick="toggle(event)">*/}
                {/*            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">*/}
                {/*                Innovative Practices - MRECW*/}
                {/*            </button>*/}
                {/*        </h2>*/}
                {/*        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">*/}
                {/*            <div className="accordion-body">*/}

                {/*                <ul className="list">*/}
                {/*                    <li>Faculty Development Programmes-National &amp; International Conferences, Workshops &amp; Refresher Courses.</li>*/}
                {/*                    <li>Employability Enhancement Training Programmes for II, III &amp; IV Year Students.</li>*/}
                {/*                    <li>Robotics Training Course from II-IV year to provide Robot Designing skills for various applications.</li>*/}
                {/*                    <li>Project Based Training for I, II, III &amp; IV B.Tech Students.</li>*/}
                {/*                    <li>Regular conduction of Mock Placement Drives for I, II, III and IV years.</li>*/}
                {/*                    <li>Finishing School Training Programmes for Final Year Students for technology enhancement.</li>*/}
                {/*                    <li>Value Added Certification Training Programmes - CISCO, Microsoft, Oracle, Business English Certification</li>*/}
                {/*                    <li>(BEC) and Oxford Achiever's Certification.</li>*/}
                {/*                    <li>Personality Development Training Programmes by Eminent Experts.</li>*/}
                {/*                    <li>Expert Lectures by persons from Reputed Universities and Industries.</li>*/}
                {/*                    <li>NPTEL Video Lectures in Digital Library &amp; Labs.</li>*/}
                {/*                    <li>LCD Projectors in every classroom to facilitate effective Teaching-Learning.</li>*/}
                {/*                    <li>Course Files, Digital Notes and Lab Manuals for Effective Instruction.</li>*/}
                {/*                    <li>Student Seminars and Conferences.</li>*/}
                {/*                    <li>Mentoring and Counseling to inspire students.</li>*/}
                {/*                    <li>Merit Scholarships for 1st &amp; 2nd Rank holders for all Departments.</li>*/}
                {/*                    <li>Young Engineer Award for the Best Performers in Final Year</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="accordion-item">*/}
                {/*        <h2 className="accordion-header" id="flush-headingThree" onclick="toggle(event)">*/}
                {/*            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">*/}
                {/*                Teaching Learning Practices - MRECW*/}
                {/*            </button>*/}
                {/*        </h2>*/}
                {/*        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">*/}
                {/*            <div className="accordion-body">*/}

                {/*                <ul className="list">*/}
                {/*                    <li>*/}
                {/*                        <p >Application Oriented Teaching</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Project Based Learning</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Content beyond Syllabus in Theory and Labs</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Course Files, Digital Notes &amp; Lab Manuals</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Assignments &amp; Tutorial Sessions</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Student Seminars &amp; Conferences</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Extra Lab sessions for English &amp; Programming Skills</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Software and Hardware Hackathons</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >Value Added Certifications</p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p >LCD Projectors for Effective Teaching</p>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="accordion-item">*/}
                {/*        <h2 className="accordion-header" id="flush-headingFour" onclick="toggle(event)">*/}
                {/*            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">*/}
                {/*                Goals - MRECW*/}
                {/*            </button>*/}
                {/*        </h2>*/}
                {/*        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">*/}
                {/*            <div className="accordion-body">*/}

                {/*                <ul className="list">*/}
                {/*                    <li><p>To provide high quality academic programmes, training activities, research facilities.</p></li>*/}
                {/*                    <li>*/}
                {/*                        <p>*/}
                {/*                            To facilitate industry - institute interaction aimed at enhancement of employability, entrepreneurship, leadership and research aptitude in the student..*/}
                {/*                        </p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p>*/}
                {/*                            To equip the students with advanced knowledge in the field of engineering as well as professional skills necessary to face the challenges of the future..*/}
                {/*                        </p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p>*/}
                {/*                            To engineer the students to engage in research activities leading to innovative applications of technology for the benefit of mankind.*/}
                {/*                        </p>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <p>To enable the students to become responsible citizens of the country with a willingness to serve the society.</p>*/}
                {/*                    </li>*/}
                {/*                    */}
                {/*<li>*/}
                {/*                    */}
                {/*    <p>*/}
                {/*                    */}
                {/*        To enquire into complaints received from the aggrieved students including ragging or from staff of the college.*/}
                {/*                    */}
                {/*    </p>*/}
                {/*                    */}
                {/*</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <ul className="accordion-list">
                  <li>
                    <h3>Special Achievements - MRECW</h3>
                    <div className="answer">
                      <ul className="list pb-4">
                        <li>Autonomous Institution, UGC, Govt. of India.</li>
                        <li>Accredited by NAAC with 'A+' Grade.</li>
                        <li>Programmes Accredited by NBA.</li>
                        <li>
                          National Ranking by NIRF-Innovation Rank Band
                          (151-300)-2023, MHRD, Govt. of India.
                        </li>
                        <li>
                          NIRF Indian Ranking-2020-Rank Band: 201-250, MHRD,
                          Govt. of India.
                        </li>
                        <li>
                          NIRF Indian Ranking-2018-Rank Band: 151-200, MHRD,
                          Govt. of India.
                        </li>
                        <li>
                          Band Excellent-National Ranking by ARIIA-2021 & 2022,
                          MHRD, Govt. of India.
                        </li>
                        <li>
                          Band (6th-25th)-National Ranking by ARIIA-2020, MHRD,
                          Govt. of India.
                        </li>
                        <li>
                          Ranked 9th in Top Engineering Colleges of Eminence by
                          GHRDC Engineering Survey-2024.
                        </li>
                        <li>
                          Ranked 13th in Top Emerging Engineering Colleges of
                          Super Excellence by CSR Rankings-2024.
                        </li>
                        <li>ISO 9001:2015 Certified.</li>
                        <li>2(f) & 12(B) Recognition, UGC, Govt. of India.</li>
                        <li>AAAA Rated by Careers 360 Magazine.</li>
                        <li>AAAA+ Rated by Digital Learning Magazine.</li>
                        <li>
                          44th Rank – Best Emerging Engineering Colleges of
                          India by India Today Rankings 2024.
                        </li>
                        <li>
                          64th Rank in T-Schools in India (Overall), 58th Rank
                          in T-Schools in India (Private) by Data Quest
                          Magazine-2024.
                        </li>
                        <li>
                          Ranked 83rd in Top Engineering Institutes of India by
                          Times Engineering Survey-2024.
                        </li>
                        <li>
                          Ranked 113th in Best Colleges in India and 7th in
                          Emerging Private Engineering Colleges in India by THE
                          WEEK Magazine – 2024.
                        </li>
                        <li>
                          Ranked as Sustainable Institutions of India with the
                          GOLD BAND by Green Rankings-2024.
                        </li>
                        <li>
                          Ranked 12th in Top Engineering Colleges of Super
                          Excellence CSR-2023.
                        </li>
                        <li>
                          Ranked Top 10 Promising Colleges for Data Science in
                          India-2023, by Higher Education Review.
                        </li>
                        <li>
                          Top 10 Women college in India-2022 by Women
                          Entrepreneurs India Magazine.
                        </li>
                        <li>
                          117th in Best Colleges in India, 8th in Emerging
                          Private Engineering Colleges in India, THE WEEK
                          Magazine - 2023.
                        </li>
                        <li>
                          Ranked 86th National Ranking by The Week
                          Magazine-2023.
                        </li>
                        <li>
                          South Asia Award 2023-24 Cambridge under Cambridge
                          Learning Partner-Continuous Learning Excellence.
                        </li>
                        <li>
                          "Best College of Year Award" from Education
                          Matters-2019.
                        </li>
                        <li>
                          "College of the Year Award" from Silicon India
                          Education-2017.
                        </li>
                        <li>
                          "Best Engineering College in Telangana & A.P. Award"
                          from International Education Award, Time Cyber Media
                          Pvt. Ltd-2019.
                        </li>
                        <li>
                          "Education Leadership Award" from Dewang Mehta
                          National Education Awards-2017.
                        </li>
                        <li>
                          "Engineering Educators Award" from University of
                          Bradford, U.K.-2021.
                        </li>
                        <li>
                          Four Star Rating from Institution Innovation Council
                          (IIC), MHRD, Govt. of India.
                        </li>
                        <li>
                          16 University Gold Medals (JNTUH University) for
                          Academic Excellence.
                        </li>
                        <li>National Employability Award-2021.</li>
                        <li>
                          National Employability Award 2022 & 2023 - for being
                          the top 10% college in India by AMCAT.
                        </li>
                        <li>
                          98% students placed in Reputed MNCs during 2023-2024.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <h3>Innovative Practices - MRECW</h3>
                    <div className="answer">
                      <ul className="list pb-4 ">
                        <li>
                          Faculty Development Programmes-National &amp;
                          International Conferences, Workshops &amp; Refresher
                          Courses.
                        </li>
                        <li>
                          Employability Enhancement Training Programmes for II,
                          III &amp; IV Year Students.
                        </li>
                        <li>
                          Robotics Training Course from II-IV year to provide
                          Robot Designing skills for various applications.
                        </li>
                        <li>
                          Project Based Training for I, II, III &amp; IV B.Tech
                          Students.
                        </li>
                        <li>
                          Regular conduction of Mock Placement Drives for I, II,
                          III and IV years.
                        </li>
                        <li>
                          Finishing School Training Programmes for Final Year
                          Students for technology enhancement.
                        </li>
                        <li>
                          Value Added Certification Training Programmes - CISCO,
                          Microsoft, Oracle, Business English Certification
                        </li>
                        <li>(BEC) and Oxford Achiever's Certification.</li>
                        <li>
                          Personality Development Training Programmes by Eminent
                          Experts.
                        </li>
                        <li>
                          Expert Lectures by persons from Reputed Universities
                          and Industries.
                        </li>
                        <li>
                          NPTEL Video Lectures in Digital Library &amp; Labs.
                        </li>
                        <li>
                          LCD Projectors in every classroom to facilitate
                          effective Teaching-Learning.
                        </li>
                        <li>
                          Course Files, Digital Notes and Lab Manuals for
                          Effective Instruction.
                        </li>
                        <li>Student Seminars and Conferences.</li>
                        <li>Mentoring and Counseling to inspire students.</li>
                        <li>
                          Merit Scholarships for 1st &amp; 2nd Rank holders for
                          all Departments.
                        </li>
                        <li>
                          Young Engineer Award for the Best Performers in Final
                          Year
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <h3>Teaching Learning Practices - MRECW</h3>
                    <div className="answer">
                      <ul className="list pb-4">
                        <li>
                          <p>Application Oriented Teaching</p>
                        </li>
                        <li>
                          <p>Project Based Learning</p>
                        </li>
                        <li>
                          <p>Content beyond Syllabus in Theory and Labs</p>
                        </li>
                        <li>
                          <p>Course Files, Digital Notes &amp; Lab Manuals</p>
                        </li>
                        <li>
                          <p>Assignments &amp; Tutorial Sessions</p>
                        </li>
                        <li>
                          <p>Student Seminars &amp; Conferences</p>
                        </li>
                        <li>
                          <p>
                            Extra Lab sessions for English &amp; Programming
                            Skills
                          </p>
                        </li>
                        <li>
                          <p>Software and Hardware Hackathons</p>
                        </li>
                        <li>
                          <p>Value Added Certifications</p>
                        </li>
                        <li>
                          <p>LCD Projectors for Effective Teaching</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <h3>Goals - MRECW</h3>
                    <div className="answer">
                      <ul className="list">
                        <li>
                          <p>
                            To provide high quality academic programmes,
                            training activities, research facilities.
                          </p>
                        </li>
                        <li>
                          <p>
                            To facilitate industry - institute interaction aimed
                            at enhancement of employability, entrepreneurship,
                            leadership qualities among the students.
                          </p>
                        </li>
                        <li>
                          <p>
                            To equip the students with advanced knowledge in the
                            field of engineering as well as professional skills
                            necessary to face the challenges of the future..
                          </p>
                        </li>
                        <li>
                          <p>
                            To engineer the students to engage in research
                            activities leading to innovative applications of
                            technology for the benefit of mankind.
                          </p>
                        </li>
                        <li>
                          <p>
                            To enable the students to become responsible
                            citizens of the country with a willingness to serve
                            the society.
                          </p>
                        </li>
                        {/*<li><p>To enquire into complaints received from the aggrieved students including ragging or from staff of the college.</p></li>*/}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/*<div className=" white-bg-text-con  mb-4 mb-lg-5">*/}
            {/*    <div className="container container--md image-grid__container fade-in fade-in--active">*/}

            {/*    </div>*/}
            {/*</div>*/}

            <div className="px-3 px-lg-0 pb-4">
              <div className="container--md card-group__container fade-in fade-in--active">
                <div className="card-group__side">
                  <h2 className="h6">
                    ORGANIZING COMMITTEE
                    <br />
                    PATRONS CHAIRS
                  </h2>
                  <div className="card-group__nav">
                    <button
                      className="h6 card-group__button card-group__button--nav  "
                      onClick={(e) => {
                        this.shoot(e, 1);
                      }}
                    >
                      <span className="card-group__button__text">
                        Founder Chairman
                      </span>
                    </button>
                    <button
                      className="h6 card-group__button card-group__button--nav"
                      onClick={(e) => {
                        this.shoot(e, 2);
                      }}
                    >
                      <span className="card-group__button__text">
                        Principal
                      </span>
                    </button>
                    <button
                      className="h6 card-group__button card-group__button--nav"
                      onClick={(e) => {
                        this.shoot(e, 4);
                      }}
                    >
                      <span className="card-group__button__text">
                        {" "}
                        Secretary
                      </span>
                    </button>
                    <button
                      className="h6 card-group__button card-group__button--nav"
                      onClick={(e) => {
                        this.shoot(e, 3);
                      }}
                    >
                      <span className="card-group__button__text">
                        President
                      </span>
                    </button>

                    {/*<button className="h6 card-group__button card-group__button--nav" onClick={(e) => {*/}
                    {/*    this.shoot(e, 5);*/}
                    {/*}}><span className="card-group__button__text">Dean Academics</span></button>*/}
                  </div>
                </div>
                <div className="card-group__main">
                  <div className="card-group__item card-group__item--top card-group__item1">
                    <button className="h6 card-group__button card-group__button--item btn1">
                      Management
                    </button>
                    <div className="card-group__card page1">
                      <a className="card-group__image">
                        <img
                          width="792"
                          height="792"
                          src="./assets/global/M_1.png"
                          className="attachment-md-lg size-md-lg"
                          alt="management"
                          loading="lazy"
                          sizes="(max-width: 792px) 100vw, 792px"
                        />
                      </a>
                      <div className="card-group__content">
                        <h3 className="h6 card-group__title">
                          <a>Shri. CH. Malla Reddy</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-group__item card-group__item2 ">
                    <button className="h6 card-group__button card-group__button--item btn2">
                      Principal
                    </button>
                    <div className="card-group__card page2">
                      <div className="card-group__content">
                        <h3 className="h6 card-group__title">
                          <a>Dr. Y. Madhavee Latha</a>
                        </h3>
                      </div>
                      <a className="card-group__image">
                        <img
                          width="792"
                          height="792"
                          src="./assets/global/m_2.png"
                          className="attachment-md-lg size-md-lg"
                          alt="management"
                          loading="lazy"
                          sizes="(max-width: 792px) 100vw, 792px"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="card-group__item card-group__item3">
                    <button className="h6 card-group__button card-group__button--item btn3">
                      Chairman
                    </button>

                    <div className="card-group__card page3">
                      <div className="card-group__content">
                        <h3 className="h6 card-group__title">
                          <a> Dr. CH. Bhadra Reddy</a>
                        </h3>
                      </div>
                      <a className="card-group__image">
                        <img
                          width="792"
                          height="792"
                          src="./assets/global/m_5.png"
                          className="attachment-md-lg size-md-lg"
                          alt="management"
                          loading="lazy"
                          sizes="(max-width: 792px) 100vw, 792px"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="card-group__item card-group__item4">
                    <button className="h6 card-group__button card-group__button--item btn4">
                      Secretay
                    </button>
                    <div className="card-group__card page4">
                      <div className="card-group__content">
                        <h3 className="h6 card-group__title">
                          <a> CH. Mahender Reddy</a>
                        </h3>
                      </div>
                      <a className="card-group__image">
                        <img
                          width="792"
                          height="792"
                          src="./assets/global/m_4.png"
                          className="attachment-md-lg size-md-lg"
                          alt="management"
                          loading="lazy"
                          sizes="(max-width: 792px) 100vw, 792px"
                        />
                      </a>
                    </div>
                  </div>
                  {/*<div className="card-group__item card-group__item5">*/}
                  {/*    <button className="h6 card-group__button card-group__button--item btn5">Dean Academics</button>*/}
                  {/*    <div className="card-group__card page5">*/}
                  {/*        <div className="card-group__content">*/}
                  {/*            <h3 className="h6 card-group__title"><a> Dr. D. Raghurami Reddy</a></h3>*/}

                  {/*        </div>*/}
                  {/*        <a className="card-group__image">*/}
                  {/*            <img width="792" height="792" src="./assets/global/M_3.png" className="attachment-md-lg size-md-lg" alt="management" loading="lazy" sizes="(max-width: 792px) 100vw, 792px" />*/}
                  {/*        </a>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>

            <div className="blog-posts">
              <div className="container--md fade-in fade-in--active">
                <div className="blog-posts__header d-flex flex-row justify-content-between">
                  <h2 className="h5 blog-posts__heading ms-4 ms-lg-0 videos-border pb-2">
                    360 Degree Virtual Tour
                  </h2>
                </div>
                <div className="mt-4 my-lg-">
                  <div className="row mx-auto">
                    <div className="col-4">
                      <a
                        href="http://staging.mallareddyecw.com/MRECWNEW/MRECWNEW.html"
                        target="_blank"
                      >
                        <img
                          src="../assets/images/media/campus-vies.png"
                          alt="img"
                        />
                        <h4 className="for-admission-text text-center">
                          <span>Campus View</span>
                        </h4>
                      </a>
                    </div>
                    <div className="col-4">
                      <a
                        href="http://staging.mallareddyecw.com/MRECWNEW/MRECWNEW.html"
                        target="_blank"
                      >
                        <img
                          src="../assets/images/media/department-labs.png"
                          alt="img"
                        />
                        <h4 className="for-admission-text text-center">
                          <span>Department Labs</span>
                        </h4>
                      </a>
                    </div>
                    <div className="col-4">
                      <a
                        href="http://staging.mallareddyecw.com/MRECWNEW/MRECWNEW.html"
                        target="_blank"
                      >
                        <img
                          src="../assets/images/media/Facilities.png"
                          alt="img"
                        />
                        <h4 className="for-admission-text text-center">
                          <span>Facilities</span>
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="news-events ">
              <div className="container container--md news-events__container fade-in fade-in--active">
                <div className="news-events__group">
                  <h4 className=" news-events__heading videos-border">
                    RECENT EVENTS
                  </h4>


                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/kaartheeka-deepotsavam-2k24.jpg"
                          target="_blank"
                        >
                          Kaartheeka Deepotsavam
                          &nbsp;&nbsp;
                        </a>
                        <img
                          className="animatedImg"
                          src="../assets/images/media/NewAnimated.gif"
                          alt="image"
                        />
                      </h6>
                      <small className="text-red">
                        13th November, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/national-education-day-2k24.jpg"
                          target="_blank"
                        >
                          National Education Day Celebrations
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">
                        11th November, 2024
                      </small>
                    </div>
                  </div>



                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/national-entrepreneurship-day-workshop-2k24.jpg"
                          target="_blank"
                        >
                          National Entrepreneurship Day Celebrations-Workshop
                          &nbsp;&nbsp;
                        </a>
                        
                      </h6>
                      <small className="text-red">
                        9th November, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/mind-over-matter-manash-mishra-2k24.jpg"
                          target="_blank"
                        >
                          MIND OVER MATTER: THE ART OF MASTERING YOUR THOUGHTS - Manash Mishra
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">
                        9th November, 2024
                      </small>
                    </div>
                  </div>




                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/industrial-visit-nuclear-fuel-complex-2k24.jpg"
                          target="_blank"
                        >
                          Industrial Visit - Nuclear Fuel Complex, Hyderabad
                          &nbsp;&nbsp;
                        </a>
                        
                      </h6>
                      <small className="text-red">
                        5th, 7th & 9th November, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/workshop-foundation-aspiring-entrepreneurs-2k24.jpg"
                          target="_blank"
                        >
                          Workshop on Foundation for Aspiring Entrepreneurs
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">
                        6th November, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/industrial-visit-nrsc-isro-2k24.jpg"
                          target="_blank"
                        >
                          Industrial Visit - National Remote Sensing Centre-ISRO, Jeedimetla, Hyderabad
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">
                        4th November, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/guest-lecture-on-industrial-turbo-alternators.jpg"
                          target="_blank"
                        >
                          Guest Lecture on Industrial Turbo Alternators
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">
                        2nd November, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/industrial-visit-shapurnagar-substation-2k24.jpg"
                          target="_blank"
                        >
                          Industrial Visit - 400/200/132/33KV Shapurnagar Substation
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">
                        29th October, 2024
                      </small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/fdp_obe-oct-2024.jpg"
                          target="_blank"
                        >
                          Faculty Development Programme on Essentials of Outcome Based Education (OBE)
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">29th October, 2024</small>
                    </div>
                  </div>











                  {/*left column ends here  */}
                  {/* recent events 2nd column  */}
                </div>
                <div className="news-events__group mt-0">
                  <div className="news-events__list pt-2 pt-lg-5 ">


                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/awareness_programme_3rs-oct-2024.jpg"
                          target="_blank"
                        >
                          Awareness Programme on 3R's - Reduce, Recycle, Reuse (NSS Unit-I & II)
                          &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">26th October, 2024</small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/awareness_programme_digitalization-oct-2024.jpg"
                          target="_blank"
                        >
                          Awareness Programme on Digitalization (NSS Unit-I & II) &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">26th October, 2024</small>
                    </div>
                  </div>



                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/MBA_ICESBA_CONFERENCE_oct_2024.pdf"
                          target="_blank"
                        >
                          {" "}
                          9th International Conference on Emerging Strategies
                          for Business Advancements (ICESBA-2024) &nbsp;
                        </a>

                      </h6>
                      <small className="text-red">
                        25th & 26th October, 2024{" "}
                      </small>
                    </div>
                  </div>
                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/H_S_ICIS_CONFERENCE_oct_2024.pdf"
                          target="_blank"
                        >
                          {" "}
                          9th International Conference on Interdisciplinary
                          Sciences (ICIS-2024) &nbsp;
                        </a>

                      </h6>
                      <small className="text-red">
                        25th & 26th October, 2024{" "}
                      </small>
                    </div>
                  </div>
                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/CSE_ICTACSE_CONFERENCE_oct_2024.pdf"
                          target="_blank"
                        >
                          {" "}
                          9th International Conference on Technical Advancements
                          in Computer Science and Engineering (ICTACSE-2024)
                          &nbsp;
                        </a>

                      </h6>
                      <small className="text-red">
                        25th & 26th October, 2024{" "}
                      </small>
                    </div>
                  </div>





                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/guest_lecture_ai-oct-2024.jpg"
                          target="_blank"
                        >
                          Guest Lecture on Artificial Intelligence &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">26th October, 2024</small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/guest_lecture_cloud_computing-oct-2024.jpg"
                          target="_blank"
                        >
                          Guest Lecture on Cloud Computing &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">26th October, 2024</small>
                    </div>
                  </div>

                  <div className="news-events__item">
                    <div className="news-events__text">
                      <h6 className="headline d-flex">
                        <a
                          className="link-alt"
                          href="../assets/events/guest_lecture_ethics_hacking-oct-2024.jpg"
                          target="_blank"
                        >
                          Guest Lecture on Ethical Hacking &nbsp;&nbsp;
                        </a>
                      </h6>
                      <small className="text-red">26th October, 2024</small>
                    </div>
                  </div>
                    




                  </div>
                  <a href="Home/Events" className="button button--inline">
                    View All Events
                  </a>
                </div>
              </div>
            </div>

            <div className="blog-posts">
              <div className="container--md fade-in fade-in--active">
                <div className=" d-flex flex-row justify-content-between ">
                  <h2 className="h5 blog-posts__heading ms-4 ms-lg-0 videos-border pb-2">
                    MRECW videos
                  </h2>
                  <a
                    href="https://www.youtube.com/@MRECWonline/featured"
                    target="_blank"
                    className="button button--inline d-none d-lg-block"
                  >
                    View all
                  </a>
                </div>
                <div className=" mb-0 my-4">
                  <div
                    id="carouselExampleIndicators3"
                    className="carousel slide"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators3"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators3"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row">
                          <div className="col-md-6 d-none d-md-block">
                            <div className="blog-posts__item">
                              <div className="blog-posts__image">
                                <iframe
                                  width="100%"
                                  height="315"
                                  src="https://www.youtube.com/embed/8ct8RA1_9CA?si=113XMJAn_FmLivtV"
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </div>
                              <div className="blog-posts__content">
                                <h3 className="headline">
                                  <a href="#" className="link-alt">
                                    R BHARGAVI REDDY DS 2020 BATCH{" "}
                                  </a>
                                </h3>
                                <div className="blog-posts__meta small text-red">
                                  <small>June 10th, 2024</small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="blog-posts__item">
                              <div className="blog-posts__image">
                                <iframe
                                  width="100%"
                                  height="315"
                                  src="https://www.youtube.com/embed/fMnvyvAdqKk?si=a29xt0d8_P9WKonp"
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </div>
                              <div className="blog-posts__content">
                                <h3 className="headline">
                                  <a href="#" className="link-alt">
                                    MRECW Graduation Day Part I
                                  </a>
                                </h3>
                                <div className="blog-posts__meta small text-red">
                                  <small>November 24th, 2023</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row">
                          <div className="col-md-6 d-none d-md-block">
                            <div className="blog-posts__item">
                              <div className="blog-posts__image">
                                <iframe
                                  width="100%"
                                  height="315"
                                  src="https://www.youtube.com/embed/-unGHPkTCNs?si=OIV8WSsP7XdPY5WN"
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </div>
                              <div className="blog-posts__content">
                                <h3 className="headline">
                                  <a href="#" className="link-alt">
                                    MRECW Go Green{" "}
                                  </a>
                                </h3>
                                <div className="blog-posts__meta small text-red">
                                  <small>November 24th, 2023</small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="blog-posts__item">
                              <div className="blog-posts__image">
                                <iframe
                                  width="100%"
                                  height="315"
                                  src="https://www.youtube.com/embed/ql6qcM8xOtE?si=fFqk-JmVYhZoXZ4k"
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </div>
                              <div className="blog-posts__content">
                                <h3 className="headline">
                                  <a href="#" className="link-alt">
                                    MRECW Success Meet{" "}
                                  </a>
                                </h3>
                                <div className="blog-posts__meta small text-red">
                                  <small>March 25th, 2023</small>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*<div className="col-md-6 d-none d-md-block">*/}
                          {/*    <div className="blog-posts__item">*/}
                          {/*        <div className="blog-posts__image">*/}
                          {/*            <iframe width="100%" height="315" src="https://www.youtube.com/embed/evilanKDPzc?si=7d5jzAhjEea5Z43p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> </div>*/}
                          {/*        <div className="blog-posts__content">*/}

                          {/*            <h3 className="headline"><a href="#" className="link-alt">MRECW AAHLAD 2K23 </a></h3>*/}
                          {/*            <div className="blog-posts__meta small text-red">*/}
                          {/*                <small>April 20th, 2023</small>*/}
                          {/*            </div>*/}
                          {/*        </div>*/}
                          {/*    </div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators3"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators3"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4 my-lg-5">
              <div id="myCarousel1" className="carousel slide">
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#myCarousel1"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#myCarousel1"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active" data-bs-interval="1500">
                    <img
                      src="./assets/banner/banner-down-1-.png"
                      className="d-block w-100"
                      alt="image"
                    />
                  </div>
                  <div className="carousel-item" data-bs-interval="1500">
                    <img
                      src="./assets/banner/banner-down-2-.png"
                      className="d-block w-100"
                      alt="image"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#myCarousel1"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#myCarousel1"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-3 order-3 order-lg-3">
            <div className="sideBtnBar mx-4 mx-lg-0 me-lg-5 pt-0">
              <aside className="Homesidebar mx-3 mx-lg-0">
                <nav className="sideNav">
                  <a href="/Home/NAAC">
                    {" "}
                    <img
                      className="img-fluid py-1 mx-auto"
                      src="./assets/navbarImages/NAAC-2023.png"
                      alt=""
                    />
                    {/*<a href="/Home/Nirf"> <img className="img-fluid py-1 mx-auto" src="./assets/navbarImages/right-2.png" alt="" />*/}
                    {/*</a>*/}
                    <a href="/Home/Ariia">
                      {" "}
                      <img
                        className="img-fluid mx-auto"
                        src="./assets/navbarImages/right-3.png"
                        alt=""
                      />
                    </a>
                    <a href="/Home/SwayamNptel">
                      {" "}
                      <img
                        className="img-fluid py-1 mx-auto"
                        src="./assets/navbarImages/right-4.png"
                        alt=""
                      />
                    </a>
                    {/*<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSd8pl0ySxXsxq4OPvmnZ-Lwf2Su03o27QDUMB9TZANhmJclqQ/viewform"> <img className="img-fluid mx-auto" src="./assets/navbarImages/right-5.png" alt="" />*/}
                    {/*</a>*/}
                  </a>
                  <a href="/Home/Hostel">
                    {" "}
                    <img
                      className="img-fluid pt-1 mx-auto"
                      src="./assets/navbarImages/right-6.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="./assets/documents/MRECW_TRANSPORT_ROUTES.pdf"
                    target="blank"
                  >
                    {" "}
                    <img
                      className="img-fluid pt-1 mx-auto"
                      src="./assets/navbarImages/right-7.png"
                      alt=""
                    />
                  </a>
                  <ul className="pt-1 secondNavbar mb-1">
                    {/*<li><a href="/Home/InnovativePractices"><i className="fa-solid fa-caret-right"></i> Innovative Practices</a></li>*/}

                    <li>
                      <a href="/Home/Standardevents">
                        <i className="fa-solid fa-caret-right"></i> Standard
                        Events
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Nirf">
                        <i className="fa-solid fa-caret-right"></i> NIRF Report
                      </a>
                    </li>
                    <li>
                      <a href="./assets/NBAnew.pdf" target="_blank">
                        <i className="fa-solid fa-caret-right"></i> NBA
                        Certificate
                      </a>
                    </li>
                    <li>
                      <a href="/assets/NAAC CERTIFICATE.pdf" target="_blank">
                        <i className="fa-solid fa-caret-right"></i> NAAC
                        Certificate
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/documents/ISO CERTIFICATES.pdf"
                        target="_blank"
                      >
                        <i className="fa-solid fa-caret-right"></i> ISO
                        Certificate
                      </a>
                    </li>

                    <li>
                      <a href="/assets/EOA_Report.pdf" target="_blank">
                        {" "}
                        <i className="fa-solid fa-caret-right"></i> AICTE LOI
                        &amp; EOA
                      </a>
                    </li>
                    <li>
                      <a href="/Home/Photogallery">
                        {" "}
                        <i className="fa-solid fa-caret-right"></i> Photo
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/ORGANIZATION_CHART  MRECW FINAL.pdf"
                        target="_blank"
                      >
                        {" "}
                        <i className="fa-solid fa-caret-right"></i> Organization
                        Chart
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/documents/administrative manual.pdf"
                        target="_blank"
                      >
                        {" "}
                        <i className="fa-solid fa-caret-right"></i>{" "}
                        Administrative Manual
                      </a>
                    </li>
                    <li>
                      <a href="/assets/documents/policies.pdf" target="_blank">
                        {" "}
                        <i className="fa-solid fa-caret-right"></i>{" "}
                        MRECW Policies
                      </a>
                    </li>

                    <li>
                      <a href="/Home/Media">
                        <i className="fa-solid fa-caret-right"></i> Media - Ads
                      </a>
                    </li>

                    <li>
                      <a href="/Home/Newsletter">
                        <i className="fa-solid fa-caret-right"></i> Newsletters
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa-solid fa-caret-right"></i> Audited
                        Statements
                      </a>
                    </li>
                    <li className="">
                      <a href="/Home/Privacypolicy">
                        <i className="fa-solid fa-caret-right"></i> Privacy
                        Policy
                      </a>
                    </li>
                    <li className="">
                      <a href="/assets/MRECW-RTI.pdf" target="_blank">
                        <i className="fa-solid fa-caret-right"></i> Right to
                        Information (RTI){" "}
                      </a>
                    </li>
                    <a
                      target="_blank"
                      className="mt-1"
                      href="https://www.youtube.com/@MRECWonline/featured"
                    >
                      <img
                        className="img-fluid mx-auto mt-1"
                        src="./assets/navbarImages/social-media/youtbe.png"
                        alt="blog"
                        loading="lazy"
                      />
                    </a>
                    <iframe
                      className="img-fluid mx-auto mt-1"
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/4Fw8wmrzPB4?si=bWt5c-pndch-gQcw"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                    <a
                      target="_blank"
                      className="mt-2"
                      href="https://www.instagram.com/mrecwautonomous/"
                    >
                      <img
                        className="img-fluid mx-auto mt-1"
                        src="./assets/navbarImages/social-media/instagram.png"
                        alt="blog"
                        loading="lazy"
                      />
                    </a>
                    <a
                      target="_blank"
                      className="mt-2"
                      href="https://www.facebook.com/MRECWonline?ref=embed_page"
                    >
                      <img
                        className="img-fluid mx-auto mt-1"
                        src="./assets/navbarImages/social-media/facebook.png"
                        alt="blog"
                        loading="lazy"
                      />
                    </a>

                    {/*  <a target='_blank' className="mt-1" href="https://www.facebook.com/photo.php?fbid=762011672604853&set=a.435904168548940&type=3&ref=embed_page">*/}
                    {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-13.jpg" alt="blog" loading="lazy" />*/}
                    {/*</a>*/}
                  </ul>
                  <a className="mt-1">
                    <iframe
                      height="400"
                      width="100%"
                      src="//www.facebook.com/plugins/likebox.php?href=https%3A%2F%2Fwww.facebook.com%2FMRECWonline&amp;width:205px;&amp;height:300px;&amp;colorscheme=light&amp;show_faces=true&amp;header=true&amp;stream=true&amp;show_border=true"
                      frameborder="0"
                      allowtransparency="true"
                    ></iframe>
                  </a>
                  {/*<a className="" href="#">*/}
                  {/*    <img className="img-fluid mx-auto mt-0 pt-0 blogFirstImg" src="./assets/navbarImages/right-8.png" alt="blog" loading="lazy" />*/}
                  {/*</a>*/}
                  {/*<a className="mt-2" href="#">*/}
                  {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-9.png" alt="blog" loading="lazy" />*/}
                  {/*</a>*/}
                  {/*<a className="mt-2" href="#">*/}
                  {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-10.png" alt="blog" loading="lazy" />*/}
                  {/*</a>*/}
                  {/*<a className="mt-2" href="#">*/}
                  {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-11.png" alt="blog" loading="lazy" />*/}
                  {/*</a>*/}
                  {/*<a className="mt-2" href="#">*/}
                  {/*    <img className="img-fluid mx-auto mt-1" src="./assets/navbarImages/right-12.png" alt="blog" loading="lazy" />*/}
                  {/*</a>*/}
                </nav>
              </aside>
            </div>
          </div>
        </div>

        {/*<div className="icon-float">*/}
        {/*    <ul>*/}
        {/*        <li>*/}
        {/*            <a href="/Home/NAAC" className="fb1 ally-focus-within">NAAC</a>*/}
        {/*        </li>*/}

        {/*    </ul>*/}
        {/*</div>*/}

        <footer className="footer">
          <div className="footer__top container--lg mx-auto ">
            <div className="container footer__top__container">
              <div className="footer__brand">
                <div className="footerLogoText">
                  <a href="#" className="footerMalareddy-logo">
                    <img
                      className="footer-logo mt-0"
                      width="400"
                      src="/assets/mallareddy-college-logo-footer.png"
                      alt="logo image"
                      loading="lazy"
                    />
                  </a>

                  {/*<div className="ms-3 pt-2">*/}
                  {/*    <h2 className="text-gray d-inline h6 fw-bolder text-uppercase">Malla Reddy Engineering<br />*/}
                  {/*        College for Women</h2>*/}

                  {/*    <small className="text-warning d-block font-small">Permanently Affiliated to JNTUH -*/}
                  {/*        Accredited by NBA &amp; NAAC with A-GRADE</small>*/}
                  {/*    <small className="text-gray d-block font-small">(Approved by AICTE, New Delhi - ISO*/}
                  {/*        9001:2015 Certified Institution)</small>*/}

                  {/*</div>*/}
                </div>
                <div>
                  <p className="pt-2" style={{ textAlign: "justify" }}>
                    Malla Reddy Engineering College for Women -MRECW (Autonomous
                    Institution, UGC, Govt. of India), established in 2008, is
                    approved by AICTE, New Delhi and affiliated to JNT
                    University, Hyderabad.
                  </p>
                  <div className="d-flex flex-row justify-content-center">
                    <a
                      href="../assets/anti ragging committee.pdf"
                      target="_blank"
                    >
                      <img
                        width="100"
                        src="./assets/global/anti-ragging.png"
                        alt="noRagging"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer__links">
                <h6 className="mt-0">Location</h6>
                <div className="py-1">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.897334329666!2d78.44954800000001!3d17.560074000000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8fa17f6df879%3A0x3160eb49f90a1a75!2sMALLA+REDDY+ENGINEERING+COLLEGE+FOR+WOMEN+(ICET+CODE%3AMRCW)!5e0!3m2!1sen!2sin!4v1436247281311"
                    height="100"
                  ></iframe>
                </div>
                <p className="footer-text mb-2">
                  Maisammaguda, Dhulapally, Post via Kompally,
                  Secunderabad-500100, Telangana, INDIA. <br />
                </p>
                <div className="d-flex footer-text mb-2">
                  <div className="me-1">Phone No :</div>
                  <div className="d-flex flex-column ">
                    <div>+91 7207231199</div>
                    <div>+91 7207031994</div>
                  </div>
                </div>

                {/*<h4 className='mt-0 mb-2 for-admission-text'><span style={{ fontSize: "10" }}>*/}
                {/*    For Admissions Call : </span> 09346118803 </h4>*/}
                <h4 className="mt-0 mb-2 py-2 for-admission-text">
                  <span>For Admissions Call: </span>93461&nbsp;18803
                </h4>
                <div className="pt-1 socialmediaIcons d-flex">
                  <a
                    href="https://www.facebook.com/MRECWonline"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook facebookIcon"></i>
                  </a>
                  <a href="https://www.youtube.com/MRECWonline" target="_blank">
                    <i className="fa-brands fa-youtube youtubeIcon"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/mrecwautonomous/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram instagramIcon"></i>
                  </a>
                  <a href="https://twitter.com/MrecwA/" target="_blank">
                    <i
                      className="fa-brands fa-twitter twitterIcon"
                      target=""
                    ></i>
                  </a>
                </div>
              </div>
              <div className="footer__related footer__150__wrapper">
                <div className="footer__150 "></div>
                <div className="footer__150__content">
                  <img
                    className=""
                    src="/assets/certified/100 percent 2022-23.jpeg"
                    alt="College Brochure"
                    loading="lazy"
                  />
                  <a
                    className="button button--inline"
                    href="https://ik.imagekit.io/buek2zb4i/MALLAREDDY%20bro%2011-2_compressed.pdf?updatedAt=1718264944595"
                    target="_blank"
                    rel="noopener"
                  >
                    College Brochure Download
                  </a>
                </div>
              </div>
              <button
                id="back-to-top"
                className="footer__back"
                data-scroll-target=".canvas"
              >
                <span className="hide">Back to top</span>
              </button>
            </div>
          </div>

          <div className="footer__bottom">
            <div className="container footer__bottom__container container--lg">
              <div className="social footer__social">
                <p className="footer__copyright">
                  &copy; Copyright 2008 - 2023. All Rights Reserved by MRECW.
                </p>
              </div>
              <div className="footer__meta">
                <div className="links-footer template align-items-center">
                  <p className="pt-4">
                    <a
                      href="https://zoninnovative.com/"
                      target="_blank"
                      className="text-blue d-flex"
                    >
                      Developed by &nbsp;
                      <img
                        width="20"
                        src="https://zoninnovative.com/conference/images/logo/zon-logo.png"
                        alt="zon logo"
                      />{" "}
                      &nbsp; Zon Innovative
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="text-block text-center ally-focus-within">
          <a href="#" className="ally-focus-within">
            <p>
              EAMCET/ICET CODE: <b>MRCW</b>
            </p>
          </a>
        </div>

        <div className="mlctr-popup" id="EnquiryForm">
          <div className="mlctr-popup-stripe" id="MlctrPopup">
            <div className="mlctr-popup-content">
              <div className="mlctr-form-box">
                <form
                  onsubmit="return mailocator.action.do('subscribe')"
                  id="MailocatorForm"
                >
                  <div className="mlctr-message-success">
                    <div className="mlctr-content">

                      <div>

                      </div>
                      <h2>Enquiry for Admissions</h2>

                      <a
                        href="http://mrecw.3pixelsonline.in/enquiry.html"
                        target="_blank"
                      >
                        <input
                          type="button"
                          value="Click Here to Submit Your Details"
                        />
                      </a>
                      <div className="mlctr-privacy"></div>
                    </div>
                  </div>
                </form>
                <a href="#EnquiryForm" id="closebtn" className="mlctr-close">
                  <img
                    className="mlctr-close"
                    src="https://www.mlcdn.eu/data/orsay/close.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
