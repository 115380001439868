import React from 'react';

function AdminSideBar() {
    return (
        <div className="sideBtnBar mx-4 mx-lg-0 ms-lg-5 pt-0">
            <aside className="Homesidebar">
                <nav className="sideNav">
                    <ul className="list-unstyled">
                        <li>
                            <a href="/Admin/Dashboard" className="d-flex align-items-center">
                                <i className="fa-solid fa-caret-right me-2"></i>
                                Dashboard
                            </a>
                        </li>

                        <li>
                            <a href="/Admin/Events" className="d-flex align-items-center">
                                <i className="fa-solid fa-caret-right me-2"></i>
                                Events
                            </a>
                        </li>
                        <li>
                            <a href="/Admin/ExamSchedules" className="d-flex align-items-center">
                                <i className="fa-solid fa-caret-right me-2"></i>
                                Exam Schedules 
                            </a>
                        </li>
                        {/* Add more list items as needed */}
                    </ul>
                </nav>
            </aside>
        </div>
    )
}

export default AdminSideBar
