import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSideBar from './AdminSideBar';

const AdminDashboard = () => {
    const events = [
        {
            eventName: "Campus Recruitment - KPIT Technologies",
            eventDate: "21st and 22nd October, 2024",
            src: "../assets/events/campus_recruitment_kpti_tech.jpg",
            description: "This is a description for the Campus Recruitment event.",
            registerNow: "https://www.mallareddyecw.com/assets/events/awareness_programme_3rs-oct-2024.jpg",
            ViewPoster: "../assets/events/campus_recruitment_kpti_tech.jpg"
        },
        {
            eventName: "Faculty Development Programme on Essentials of Outcome Based Education (OBE)",
            eventDate: "29th October, 2024",
            src: "../assets/events/fdp_obe-oct-2024.jpg",
            description: "This is a description for the Faculty Development Programme.",
            registerNow: "https://www.mallareddyecw.com/assets/events/awareness_programme_3rs-oct-2024.jpg",
            ViewPoster: "../assets/events/fdp_obe-oct-2024.jpg"
        },
    ];

    

    return (
        <>
            <AdminHeader />

            <div className="row card-group">
                <div className="col-lg-3 order-2 order-lg-1">
                    <AdminSideBar />
                </div>
                <div className='col-lg-9 order-1 order-lg-2'>
                    <div className='container'>
                        <div className="news-events pt-0">
                            <div className="container container--md news-events__container fade-in fade-in--active">
                                <div className="news-events__group">
                                    <h4 className="news-events__heading videos-border">
                                        RECENT EVENTS
                                    </h4>

                                    {events.map((event, index) => (
                                        <div className="news-events__item" key={index}>
                                            <div className="news-events__text">
                                                {event.eventName.length > 1 && (
                                                    <h6 className="headline d-flex">
                                                        <a
                                                            className="link-alt"
                                                            href={event.ViewPoster}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {event.eventName}
                                                            &nbsp;&nbsp;
                                                        </a>
                                                        <img
                                                            className="animatedImg"
                                                            src="../assets/images/media/NewAnimated.gif"
                                                            alt="image"
                                                        />
                                                    </h6>
                                                )}
                                                {event.eventDate.length > 1 && (
                                                    <small className="text-red">
                                                        {event.eventDate}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;
