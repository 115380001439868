import React, { useState } from 'react';
import AdminHeader from './AdminHeader'; 
import AdminSideBar from './AdminSideBar'; 

function AdminExamSchedules() {
    const [events, setEvents] = useState([]); 
    const [eventTitle, setEventTitle] = useState('');
    const [file, setFile] = useState(null);
    const [eventType, setEventType] = useState(''); // Type: BTech, MBA, MTech

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newEvent = {
            title: eventTitle,
            type: eventType,
        };

        try {
            const formData = new FormData();
            formData.append('event', JSON.stringify(newEvent));
            if (file) {
                formData.append('file', file);
            }

            const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Event posted:', data);
                setEvents([...events, newEvent]);
                resetForm();
            } else {
                console.error('Failed to post event:', response.status);
            }
        } catch (error) {
            console.error('Error posting event:', error);
        }
    };

    const resetForm = () => {
        setEventTitle('');
        setFile(null);
        setEventType('');
    };

    return (
        <>
            <AdminHeader />
            <div className="row card-group">
                <div className="col-lg-3 order-2 order-lg-1">
                    <AdminSideBar />
                </div>
                <div className='col-lg-9 order-1 order-lg-2'>
                    <div className='container pt-0'>
                        <h2 className='h5 mt-0'>Post Exam Schedules Here</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className='fw-bold'>Event Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={eventTitle}
                                    onChange={(e) => setEventTitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className='fw-bold'>Type:</label>
                                <select
                                    className="form-control"
                                    value={eventType}
                                    onChange={(e) => setEventType(e.target.value)}
                                    required
                                >
                                    <option value="">Select Type</option>
                                    <option value="BTech">BTech</option>
                                    <option value="MBA">MBA</option>
                                    <option value="MTech">MTech</option>
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                <label className='fw-bold'>Upload File (Image/PDF):</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    accept="image/*,.pdf"
                                    onChange={handleFileChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary ms-auto mt-3">Add Exam Schedule</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminExamSchedules;
